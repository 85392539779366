body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100% !important;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

*:focus {
  box-shadow: none !important;
  border-color: rgb(126, 126, 126) !important;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Medium"),
    url("./fonts/Gilroy-Medium.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Gilroy";
  src: local("Gilroy-Bold"), url("./fonts/Gilroy-Bold.ttf") format("truetype");
  font-weight: 800;
}

@font-face {
  font-family: "Charlie-Kingdom";
  src: local("Charlie-Kingdom"),
    url("./fonts/Charlie-Kingdom.ttf") format("truetype");
  font-weight: 600;
}
